import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import MainBackTests from './backTests/index.js';
import Forecast from './forecast';
import Methodology from './methodology';
import { useParams } from 'react-router';
import { useDispatch } from 'store';
import { useNavigate } from 'react-router';
import {
  deletePortfolios,
  deleteSubCategory,
  getCategoriesList,
  getSubCategoriesList,
  getSubCategoriesListByCategory,
  putSubCategory,
} from 'store/slices/categories';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: theme.shadows[6],
  borderRadius: theme.spacing(1),
  height: '100%',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.custom.secondary,
    height: 4,
  },
}));

const Content = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [visible, setVisible] = useState(true);
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [forceRender, setForceRender] = useState(false); // State to force re-render
  const { categoryId, subCategoryId } = useParams();
  const { updateSubCategory, updateCategory } = useSelector(
    (state) => state.commonReducer
  );

  const isAuthenticated = () => {
    return !!localStorage.getItem('ucla-token');
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    setBannerImage(
      `${process.env.REACT_APP_BASE_URL}${
        selectedSubCategory?.cover_image_url || selectedSubCategory?.cover_image
      }?t=${new Date().getTime()}`
    );
  }, [selectedSubCategory]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  useEffect(() => {
    setTabIndex(0);
    const payload = {
      categoryId: categoryId,
      skip: skip,
      limit: limit,
      type: subCategoryId ? 'assets' : 'portfolio',
    };
    dispatch(getSubCategoriesListByCategory(payload))
      .then((res) => {
        if (res?.status === 200) {
          const data = res.data?.data || res.data;
          let selectedItem = {};
          if (payload.type === 'assets') {
            selectedItem = data.find(
              (item) => item._id === subCategoryId || item.id === subCategoryId
            );
          } else {
            selectedItem = data.find(
              (item) => item._id === categoryId || item.id === categoryId
            );
          }

          setVisible(selectedItem?.visible);
          setSelectedSubCategory(selectedItem);
          setForceRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [
    dispatch,
    subCategoryId,
    updateSubCategory,
    updateCategory,
    categoryId,
    skip,
    limit,
  ]);

  const handleClick = () => {
    setVisible(!visible);
    const payload = {
      id: subCategoryId || categoryId,
    };
    dispatch(
      putSubCategory({
        ...selectedSubCategory,
        ...payload,
        visible: !visible,
        type: subCategoryId ? 'assets' : 'portfolio',
      })
    )
      .then((res) => {
        if (res?.status === 200) {
          toast.success('Visibility updated successfully');
        }
      })
      .catch((err) => {
        toast.error(err?.detail || 'Failed to update visibility');
      });
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteForecast = () => {
    const payload = {
      _id: subCategoryId,
    };
    if (subCategoryId) {
      dispatch(deleteSubCategory(payload))
        .then((res) => {
          if (res?.status === 200) {
            setOpenDeleteDialog(false);
            dispatch(getCategoriesList(skip, limit));
            dispatch(getSubCategoriesList(skip, limit));
            navigate('/');
            toast.success('Asset deleted successfully');
          }
        })
        .catch((err) => {
          toast.error(err?.detail);
        });
    } else {
      dispatch(deletePortfolios(categoryId))
        .then((res) => {
          if (res?.status === 200) {
            setOpenDeleteDialog(false);
            dispatch(getCategoriesList(skip, limit));
            dispatch(getSubCategoriesList(skip, limit));
            navigate('/');
            toast.success('Portfolio deleted successfully');
          }
        })
        .catch((err) => {
          navigate('/');
          toast.success('Portfolio deleted successfully');
        });
    }
  };

  return (
    <Grid key={forceRender} container sx={{ position: 'relative' }}>
      <Grid item xs={12}>
        <Box
          sx={{
            position: 'relative',
            height: '250px',
            background: `url(${bannerImage}) no-repeat center center`,
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            textShadow: '0px 0px 10px rgba(0,0,0,0.5)',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            },
            '& > *': {
              position: 'relative',
              zIndex: 2,
            },
          }}
        >
          <Stack direction={'row'} alignItems={'baseline'} gap={1}>
            <Typography variant="f52">{selectedSubCategory?.name}</Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <Switch
                checked={visible}
                onChange={handleClick}
                sx={{
                  '& .MuiSwitch-track': {
                    backgroundColor: '#b9b9b9',
                  },
                }}
              />
              <Typography variant="f16" fontWeight={600}>
                {visible ? 'Yes' : 'No'}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={12} px={3}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            top: -60,
            zIndex: 10,
          }}
        >
          <StyledCard>
            <StyledTabs
              value={tabIndex}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="forecast tabs"
            >
              <Tab label="Live Forecast" />
              <Tab label="Backtest" />
              <Tab label="Methodology" />
            </StyledTabs>
            <Box py={3}>
              {tabIndex === 0 && (
                <Box>
                  <Forecast />
                </Box>
              )}
              {tabIndex === 1 && (
                <Box>
                  <MainBackTests />
                </Box>
              )}
              {tabIndex === 2 && (
                <Box>
                  <Methodology />
                </Box>
              )}
            </Box>
          </StyledCard>
        </Box>
      </Grid>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Asset</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this asset?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={handleCloseDeleteDialog}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteForecast}
            color="error"
            autoFocus
            sx={{ color: 'white' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={12} my={5}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenDeleteDialog(true);
            }}
            startIcon={<DeleteIcon />}
            sx={{
              borderColor: 'error.main',
              color: 'error.main',
              '&:hover': {
                borderColor: 'error.dark',
              },
            }}
          >
            Delete {selectedSubCategory?.name}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Content;