import { useTheme } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import LightButton from "ui-component/button/LightButton";
import AnimateButton from "ui-component/extended/AnimateButton";

const DropzoneContainerStyled = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(4),
  border: `1px dashed ${theme.palette.custom.primary}`,
  backgroundColor: theme.palette.background.paper,
  cursor: "pointer",
  transition: "border 0.3s ease-in-out",
  "&:hover": {
    border: `1px dashed ${theme.palette.primary.dark}`,
  },
}));

const FileUploadWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.text.primary40}`,
  borderRadius: theme.spacing(0.5),
  gap: theme.spacing(1),
  transition: "border 0.3s ease-in-out",
}));

const DropzoneContainer = ({
  file,
  setFile,
  uploadProgress,
  setUploadProgress,
  buttonName,
  title,
}) => {
  const theme = useTheme();
  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      setFile(newFile);
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
      };

      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress(progress);
        }
      };

      reader.readAsText(newFile);
    },
    [setFile, setUploadProgress]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: '.csv',
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const handleDelete = () => {
    setFile(null);
    setUploadProgress(0);
  };

  return (
    <>
      {!file ? (
        <DropzoneContainerStyled {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography
            variant="f16"
            color="text.primary60"
            sx={{ mb: 2 }}
            textAlign={'center'}
          >
            {isDragActive ? 'Drop the CSV file here ...' : title}
          </Typography>
          <AnimateButton>
            <LightButton
              startIcon={<UploadIcon />}
              onClick={open}
              title={buttonName}
            />
          </AnimateButton>
        </DropzoneContainerStyled>
      ) : (
        <FileUploadWrapper>
          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              width: '100%',
            }}
          >
            <Stack
              direction={'row'}
              alignItems={'flex-start'}
              flexGrow={1}
              gap={2}
            >
              <FilePresentIcon
                sx={{ mt: 0.5, color: theme.palette.text.primary60 }}
              />
              <Stack
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant="f18" fontWeight={500}>
                  {file.name}
                </Typography>
                <Typography
                  variant="f14"
                  fontWeight={400}
                  color="text.primary50"
                >
                  {(file.size / 1024).toFixed(2)} KB
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  sx={{ my: 2 }}
                />
              </Stack>
            </Stack>
            <IconButton onClick={handleDelete}>
              <DeleteIcon color="error" />
            </IconButton>
          </Stack>
        </FileUploadWrapper>
      )}
    </>
  );
};

export default DropzoneContainer;
