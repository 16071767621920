import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import { useDispatch } from "store";
import {
  deleteForecastItem,
  downloadForeCastCSV,
  downloadPortFolioForeCastCSV,
  getForecastsList,
  postForecast,
  putForecast,
  uploadForeCastCSV,
} from 'store/slices/forecasts';
import LightButton from 'ui-component/button/LightButton';
import PrimaryButton from 'ui-component/button/PrimaryButton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Loader from 'ui-component/Loader';
import DropzoneContainer from '../backTests/DropzoneContainer';
import CloseIcon from '@mui/icons-material/Close';

const apiUrl = 'https://your-api-endpoint.com'; // Replace with your actual API endpoint

const signalOptions = [
  { label: 'LONG', value: 'LONG' },
  { label: 'SHORT', value: 'SHORT' },
  { label: 'N/A', value: 'N/A' },
];

const selectHorizon = [
  { label: 'Short Term', value: 'Short Term' },
  { label: 'Long Term', value: 'Long Term' },
];

const ForecastTable = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { categoryId, subCategoryId } = useParams();
  const dispatch = useDispatch();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const [forecastsData, setForecastsData] = useState([]);

  const handleClickOpen = () => {
    setOpen(true); // Open dialog
  };

  const handleClose = () => {
    setOpen(false); // Close dialog
    setFile();
  };

  const [newForecast, setNewForecast] = useState({
    signal: '',
    interest: '',
    date_of_forecast: dayjs(),
    date_of_exit: '',
    horizon: '', // Added horizon field
  });

  const [errors, setErrors] = useState({}); // To manage validation errors

  const columns = useMemo(
    () => [
      {
        accessorKey: 'signal',
        header: 'Signal',
        Cell: ({ cell }) => (
          <span
            style={{ color: cell.getValue() === 'SHORT' ? 'red' : 'green' }}
          >
            {cell.getValue()}
          </span>
        ),
      },
      {
        accessorKey: 'interest',
        header: 'Interest',
      },
      {
        accessorKey: 'date_of_forecast',
        header: 'Forecast Date',
        Cell: ({ cell }) => (
          <span>
            {cell.getValue()
              ? dayjs(cell.getValue()).format('YYYY-MM-DD')
              : 'N/A'}
          </span>
        ),
      },
      {
        accessorKey: 'date_of_exit',
        header: 'Exit Date',
        Cell: ({ cell }) => (
          <span>
            {cell.getValue()
              ? dayjs(cell.getValue()).format('YYYY-MM-DD')
              : 'N/A'}
          </span>
        ),
      },
    ],
    []
  );
  const fetchData = async () => {
    const response = await dispatch(
      getForecastsList({
        assetsId: subCategoryId || categoryId,
        limit,
        skip,
        type: subCategoryId ? 'assets' : 'portfolio',
      })
    );
    return response;
  };

  const {
    data: forecasts = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['forecasts'],
    queryFn: async () => {
      const response = await fetchData();
      return response.data;
    },
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    refetch();
  }, [subCategoryId, limit, skip, open]);

  useEffect(() => {
    setForecastsData(forecasts.slice()?.reverse());
  }, [forecasts]);

  const createForecast = useMutation({
    mutationFn: async (newForecast) => {
      const payload = {
        asset_id: subCategoryId || categoryId,
        signal: newForecast.signal,
        interest: newForecast.interest,
        date_of_forecast: newForecast.date_of_forecast || null,
        date_of_exit: newForecast.date_of_exit || null,
        horizon: newForecast.horizon,
      };
      await dispatch(postForecast(payload))
        .then((res) => {
          if (res.status === 200) {
            toast.success('Forecast added successfully');
          }
        })
        .catch((error) => {
          toast.error(error?.detail || 'Failed to add forecast');
        });
    },
    onSuccess: () => {
      refetch(); // Ensure the list is refetched after a successful create
    },
  });

  const updateForecast = useMutation({
    mutationFn: async (updatedForecast) => {
      const payload = {
        asset_id: subCategoryId || categoryId,
        signal: updatedForecast.signal,
        interest: updatedForecast.interest,
        date_of_forecast: updatedForecast.date_of_forecast || null,
        date_of_exit: updatedForecast.date_of_exit || null,
        horizon: updatedForecast.horizon,
        _id: updatedForecast._id,
      };
      await dispatch(putForecast(payload))
        .then((res) => {
          if (res.status === 200) {
            toast.success('Forecast updated successfully');
          }
        })
        .catch((error) => {
          toast.error(error?.detail || 'Failed to update forecast');
        });
    },
    onSuccess: () => {
      refetch(); // Ensure the list is refetched after a successful update
    },
  });

  const deleteForecast = useMutation({
    mutationFn: async (forecastId) => {
      await dispatch(deleteForecastItem({ forecastId }));
    },
    onSuccess: () => {
      refetch(); // Ensure the list is refetched after a successful delete
    },
  });

  const handleCreateForecast = async () => {
    const validationErrors = validateForecast(newForecast);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    await createForecast.mutateAsync(newForecast);
    setOpenEditDialog(false);
    setNewForecast({
      signal: '',
      interest: '',
      date_of_forecast: dayjs(),
      date_of_exit: '',
      horizon: '', // Reset horizon field
    });
    setErrors({});
  };

  const handleSaveForecast = async () => {
    const validationErrors = validateForecast(selectedRow.original);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    await updateForecast.mutateAsync(selectedRow.original);
    setOpenEditDialog(false);
    setSelectedRow(null);
    setErrors({});
  };

  const handleDeleteForecast = async () => {
    await deleteForecast.mutateAsync(selectedRow.original._id);
    setOpenDeleteDialog(false);
    setSelectedRow(null);
  };

  const handleOpenDeleteDialog = (row) => {
    setSelectedRow(row);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedRow(null);
  };

  const handleOpenEditDialog = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);
  };

  const handleOpenAddDialog = () => {
    setNewForecast({
      signal: '',
      interest: '',
      date_of_forecast: dayjs(),
      date_of_exit: '',
      horizon: '', // Set horizon field
    });
    setOpenEditDialog(true);
  };

  const handleInputChange = (field, value) => {
    if (selectedRow) {
      setSelectedRow((prev) => ({
        ...prev,
        original: { ...prev.original, [field]: value },
      }));
    } else {
      setNewForecast((prev) => ({ ...prev, [field]: value }));
    }
  };

  const validateForecast = (forecast) => {
    const errors = {};
    if (!forecast.signal) errors.signal = 'Signal is required';
    if (forecast.interest === '' || forecast.interest === undefined)
      errors.interest = 'Interest is required';
    if (forecast.date_of_forecast === 'Invalid Date')
      errors.date_of_forecast = 'Forecast Date is invalid';
    if (forecast.date_of_exit === 'Invalid Date')
      errors.date_of_exit = 'Exit Date is invalid';
    if (!forecast.horizon) errors.horizon = 'Horizon is required'; // Validate horizon
    return errors;
  };

  const handleUploadCSV = () => {
    const assetsId = subCategoryId || categoryId;
    const formData = new FormData();
    formData.append('file', file);

    dispatch(uploadForeCastCSV(assetsId, formData))
      .then(async (res) => {
        if (res?.data?.message === 'Invalid CSV Format') {
          toast.error(res?.data?.message);
        } else {
          setFile(null); // Clear the file after upload
          setOpen(false); // Close the dialog after successful upload
          toast.success('Forecasts imported successfully');
        }
      })
      .catch((err) => {
        toast.error('Error uploading file');
      });
  };

  const handleClickDownload = () => {
    const assetsId = subCategoryId || categoryId;
    const type = subCategoryId ? 'assets' : 'portfolios';
    dispatch(downloadForeCastCSV(assetsId, type))
      .then((res) => {
        const csvData = res.data; // CSV data in string format
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `forecast_${assetsId}.csv`; // Custom file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        toast.success('Forecasts downloaded successfully');
      })
      .catch((err) => {
        toast.error('Error downloading file');
      });
  };

  const table = useMaterialReactTable({
    columns,
    getRowId: (row) => row.id,
    data: forecastsData,
    enableEditing: true,
    positionActionsColumn: 'last',
    muiPaginationProps: {
      showFirstButton: false,
      showLastButton: false,
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleOpenEditDialog(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleOpenDeleteDialog(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <AnimateButton>
        <LightButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddDialog}
          title="Add Forecast"
          style={{
            padding: '8px 20px',
          }}
        />
      </AnimateButton>
    ),
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Stack mb={2} direction={'row'} justifyContent={'flex-end'} gap={2}>
        <AnimateButton>
          <PrimaryButton title={'Import Forecast'} onClick={handleClickOpen} />
        </AnimateButton>

        <AnimateButton>
          <PrimaryButton
            title={'Export Forecast'}
            onClick={handleClickDownload}
          />
        </AnimateButton>
      </Stack>

      <MaterialReactTable table={table} />
      {/* Delete */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Forecast</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this forecast?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={handleCloseDeleteDialog}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteForecast}
            color="error"
            autoFocus
            sx={{ color: 'white' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle id="edit-dialog-title">
          {selectedRow ? 'Edit Forecast' : 'Add Forecast'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Signal</InputLabel>
              <Autocomplete
                fullWidth
                options={signalOptions}
                getOptionLabel={(option) => option.label}
                value={signalOptions.find(
                  (option) =>
                    option.value ===
                    (selectedRow?.original.signal || newForecast?.signal)
                )}
                onChange={(event, newValue) =>
                  handleInputChange('signal', newValue?.value || '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Signal"
                    error={Boolean(errors.signal)}
                    helperText={errors.signal}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Interest</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Interest (in %)"
                value={selectedRow?.original.interest || newForecast.interest}
                onChange={(e) => handleInputChange('interest', e.target.value)}
                error={Boolean(errors.interest)}
                helperText={errors.interest}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Forecast Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  placeholder="Forecast Date"
                  value={
                    selectedRow && dayjs(selectedRow.original.date_of_forecast)
                  }
                  onChange={(newValue) =>
                    handleInputChange(
                      'date_of_forecast',
                      newValue ? newValue.format('YYYY-MM-DD') : ''
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={Boolean(errors.date_of_forecast)}
                      helperText={errors.date_of_forecast}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Exit Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  placeholder="Exit Date"
                  value={
                    selectedRow &&
                    selectedRow.original.date_of_exit &&
                    dayjs(selectedRow.original.date_of_exit)
                  }
                  onChange={(newValue) =>
                    handleInputChange(
                      'date_of_exit',
                      newValue ? newValue.format('YYYY-MM-DD') : ''
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={Boolean(errors.date_of_exit)}
                      helperText={errors.date_of_exit}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Select Horizon</InputLabel>
              <Autocomplete
                fullWidth
                options={selectHorizon}
                getOptionLabel={(option) => option.label}
                value={selectHorizon.find(
                  (option) =>
                    option.value ===
                    (selectedRow?.original.horizon || newForecast.horizon)
                )}
                onChange={(event, newValue) =>
                  handleInputChange('horizon', newValue?.value || '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Horizon"
                    error={Boolean(errors.horizon)}
                    helperText={errors.horizon}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AnimateButton>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleCloseEditDialog}
              style={{
                boxShadow: 'none',
              }}
            >
              Cancel
            </Button>
          </AnimateButton>
          <AnimateButton>
            <PrimaryButton
              onClick={selectedRow ? handleSaveForecast : handleCreateForecast}
              title={selectedRow ? 'Save Forecast' : 'Add Forecast'}
            />
          </AnimateButton>
        </DialogActions>
      </Dialog>

      {/* Import */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography>Upload Forecast</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} mt={2}>
            <DropzoneContainer
              file={file}
              setFile={setFile}
              uploadProgress={uploadProgress}
              setUploadProgress={setUploadProgress}
              title={
                'Drag and drop a CSV file here, or click the button below to select one'
              }
              buttonName={'Upload Forecast CSV'}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            width="100%"
            p={2}
          >
            <AnimateButton>
              <PrimaryButton
                title="Save"
                style={{
                  padding: '8px 20px',
                }}
                onClick={handleUploadCSV}
                disabled={!file}
              />
            </AnimateButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

const queryClient = new QueryClient();

const Forecast = () => (
  <Box>
    <QueryClientProvider client={queryClient}>
      <ForecastTable />
    </QueryClientProvider>
  </Box>
);

export default Forecast;
