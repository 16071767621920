// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  authData: {},
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    loginSuccess(state, action) {
      state.authData = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { hasError, loginSuccess } = slice.actions;

// ----------------------------------------------------------------------
export function login(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/token', payload);
      dispatch(loginSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
