import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCapture,
  getCaptures,
  deleteCapture,
  restoreCapture,
} from 'store/slices/captures';

import LightButton from 'ui-component/button/LightButton';
import PrimaryButton from 'ui-component/button/PrimaryButton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DropzoneContainer from 'views/content/backTests/DropzoneContainer';

const BackupTable = ({
  handleOpenDialog,
  handleClickOpenRestore,
  handleOpenDeleteDialog,
}) => {
  const dispatch = useDispatch();
  const { captureList } = useSelector((state) => state?.captures);
  useEffect(() => {
    dispatch(getCaptures());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Date and Time',
        Cell: ({ cell }) => (
          <>
            {moment(cell?.id.split('__')[0], 'YYYY-MM-DD_HH.mm.ss').format(
              'DD-MM-YYYY  hh:mm a'
            )}
          </>
        ),
      },
      {
        accessorKey: 'name',
        header: 'Title',
        Cell: ({ cell }) => <span>{cell.getValue()}</span>,
      },
      {
        accessorKey: 'desc',
        header: 'Description',
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    getRowId: (row) => row.id,
    data: captureList || [],
    enableEditing: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <AnimateButton>
          <PrimaryButton
            onClick={() => handleClickOpenRestore(row)}
            title="Restore"
          />
        </AnimateButton>

        <AnimateButton>
          <Button
            variant="contained"
            color="error"
            sx={{
              color: 'white',
            }}
            onClick={() => handleOpenDeleteDialog(row)} // Open confirmation dialog
          >
            Delete
          </Button>
        </AnimateButton>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <AnimateButton>
        <LightButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog} // Trigger the dialog from outside
          title="Create backup"
          style={{
            padding: '8px 20px',
          }}
        />
      </AnimateButton>
    ),
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const Backups = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const [backupData, setBackupData] = useState({ title: '', description: '' });
  const [openRestore, setOpenRestore] = useState(false);

  const handleClickOpenRestore = (row) => {
    dispatch(restoreCapture(row?.id))
      .then((res) => {
        toast.success('Restore backup successfully.');
      })
      .catch((err) => {
        toast.error('Error while restoring backup.');
      });
  };

  const handleOpenDeleteDialog = (row) => {
    setSelectedRow(row); // Set the row to be deleted
    setOpenDeleteDialog(true); // Open the dialog
  };

  const handleCloseDeleteDialog = () => {
    setSelectedRow(null); // Clear selected row
    setOpenDeleteDialog(false); // Close the dialog
  };

  const handleDeleteBackup = () => {
    dispatch(deleteCapture(selectedRow?.id))
      .then(() => {
        toast.success('Backup deleted successfully');
        dispatch(getCaptures()); // Refresh the list after deletion
        handleCloseDeleteDialog(); // Close the dialog
      })
      .catch(() => {
        toast.error('Failed to delete backup');
        handleCloseDeleteDialog();
      });
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setBackupData({ title: '', description: '' });
  };

  const handleCreateBackup = () => {
    const payload = {
      name: backupData?.title,
      desc: backupData?.description,
    };
    dispatch(createCapture(payload)).then((res) => {
      dispatch(getCaptures());
      toast.success('Backup saved successfully');
    });
    handleCloseDialog();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBackupData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        margin: '0 auto',
        padding: '2rem',
        borderRadius: '10px',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1,
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Box mb={3}>
        <Typography variant="f32">Backups</Typography>
      </Box>
      <QueryClientProvider client={new QueryClient()}>
        <BackupTable
          handleOpenDialog={handleOpenDialog}
          handleClickOpenRestore={handleClickOpenRestore}
          handleOpenDeleteDialog={handleOpenDeleteDialog} // Pass delete dialog handler
        />
      </QueryClientProvider>

      {/* Dialog for creating backup */}
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Create New Backup</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Title</InputLabel>
              <TextField
                id="title"
                name="title"
                placeholder="Enter title"
                type="text"
                fullWidth
                value={backupData.title}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Description</InputLabel>
              <TextField
                id="description"
                name="description"
                type="text"
                fullWidth
                multiline
                rows={2}
                maxRows={4}
                value={backupData.description}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AnimateButton>
            <Button
              onClick={handleCloseDialog}
              variant="contained"
              color="inherit"
              style={{
                boxShadow: 'none',
              }}
            >
              Cancel
            </Button>
          </AnimateButton>
          <AnimateButton>
            <PrimaryButton onClick={handleCreateBackup} title="Create Backup" />
          </AnimateButton>
        </DialogActions>
      </Dialog>

      {/* Restore Dialog */}
      <Dialog
        open={openRestore}
        onClose={() => setOpenRestore(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography>Restore Backups</Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenRestore(false)}
              sx={{
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} mt={2}>
            <DropzoneContainer
              title="Drag and drop a CSV file here, or click the button below to select one"
              buttonName="Upload Backups CSV"
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            width="100%"
            p={2}
          >
            <AnimateButton>
              <PrimaryButton
                title="Save"
                style={{
                  padding: '8px 20px',
                }}
              />
            </AnimateButton>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Backup</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this backup?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={handleCloseDeleteDialog}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteBackup}
            color="error"
            autoFocus
            sx={{ color: 'white' }}
          >
            Delete
          </Button>
        </DialogActions>
        {/* <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleDeleteBackup} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions> */}
      </Dialog>
    </Box>
  );
};

export default Backups;
