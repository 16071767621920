import { Navigate, createBrowserRouter } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import AuthRoutes from "./AuthRoutes";

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter([
  MainRoutes,
  AuthRoutes,
  { path: '*', element: <Navigate to="/login" /> },
]);

export default router;
