import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  isMobile: false,
  updateSubCategory: false,
  updateCategory: false,
};

// ==============================|| SLICE - COMMON ||============================== //

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    checkIsMobile(state, action) {
      state.isMobile = action.payload;
    },
    isUpdateSubCategory(state, action) {
      state.updateSubCategory = action.payload;
    },
    isUpdatedCategory(state, action) {
      state.updateCategory = action.payload;
    },
  },
});

export default common.reducer;

export const { checkIsMobile, isUpdatedCategory, isUpdateSubCategory } =
  common.actions;
