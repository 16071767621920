// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  authData: {},
  evalMetricsList: [],
};

const slice = createSlice({
  name: 'forecasts',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    evalMetricsSuccess(state, action) {
      state.evalMetricsList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { hasError, evalMetricsSuccess } = slice.actions;

// ----------------------------------------------------------------------
export function getEvalMetrics(payload) {
  return async (dispatch) => {
    try {
      let response;
      if (payload?.type === 'assets') {
        response = await axios.get(
          `/assets/${payload?.assetId}/evalmetrics/?skip=${payload?.skip}&limit=${payload?.limit}`
        );
      } else {
        response = await axios.get(
          `/portfolios/${payload?.assetId}/evalmetrics/?skip=${payload?.skip}&limit=${payload?.limit}`
        );
      }
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function getBackTest(payload) {
  return async (dispatch) => {
    try {
      let response = '';
      if (payload?.type === 'assets') {
        response = await axios.get(
          `/assets/${payload?.assetId}/backtests/?skip=${payload?.skip}&limit=${payload?.limit}`
        );
      } else {
        response = await axios.get(
          `/portfolios/${payload?.assetId}/backtests/?skip=${payload?.skip}&limit=${payload?.limit}`
        );
      }
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function postEvalMetrics(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/evalmetrics`, formData);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function putEvalmetrics(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/evalmetrics/${payload?._id}`, payload);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function deleteEvalmetrics(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/evalmetrics/${payload?._id}`);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function uploadCSV(assetsId, payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/evalmetrics/csv/?asset_id=${assetsId}`,
        payload
      );
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function uploadBackTestsCSV(assetsId, payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/backtests/csv/?asset_id=${assetsId}`,
        payload
      );
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function downloadForeBackTestCSV(assetsId, type) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${type}/${assetsId}/backtests/csv`);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function downloadForeMatricCSV(assetsId, type) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${type}/${assetsId}/evalmetrics/csv`);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
