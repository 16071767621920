import { Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Grid
      container
      style={{
        backgroundColor: "#f1f1f1",
        padding: "10px 0",
        marginTop: "auto",
      }}
    >
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Typography
            variant="f18"
            sx={{
              color: "text.primary50",
            }}
          >
            © 2024
          </Typography>
          <Typography
            variant="f18"
            component="a"
            href="https://master.econ.ucla.edu/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "custom.primary",
              fontWeight: 700,
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            UCLA MAE
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
