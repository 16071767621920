import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// project import
import useConfig from "hooks/useConfig";
import Palette from "./palette";
import Typography from "./typography";

import componentStyleOverrides from "./compStyleOverride";

export default function ThemeCustomization({ children }) {
  const { config } = useConfig();
  const { borderRadius, fontFamily, navType, outlinedFilled } = config;

  const theme = useMemo(() => Palette(navType), [navType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = useMemo(
    () => Typography(theme, borderRadius, fontFamily),
    [theme, borderRadius, fontFamily]
  );

  const themeOptions = useMemo(
    () => ({
      palette: theme.palette,
      typography: themeTypography,
    }),
    [theme, themeTypography]
  );

  const themes = createTheme(themeOptions);
  themes.components = useMemo(
    () => componentStyleOverrides(themes, borderRadius, outlinedFilled),
    [themes, borderRadius, outlinedFilled]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.propTypes = {
  children: PropTypes.node,
};
