import { Box, CssBaseline, styled, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

const Main = styled("main")(({ theme }) => ({
  height: "100vh",
  width: "100%",
  flexGrow: 1,
  padding: theme.spacing(2),
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.background.default,

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 0),
  },
}));

const MinimalLayout = () => {
  const theme = useTheme();

  return (
    <Box>
      <CssBaseline />
      <Main theme={theme}>
        <Outlet />
      </Main>
    </Box>
  );
};

export default MinimalLayout;
