import {
  Badge as BadgeIcon,
  Close as CloseIcon,
  Subtitles as SubtitlesIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { useDispatch } from 'store';
import {
  getCategoriesList,
  getSubCategoriesList,
  postSubCategories,
  putSubCategory,
  updatePortfolioImage,
} from 'store/slices/categories';
import PrimaryButton from 'ui-component/button/PrimaryButton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DropzoneContainer from 'views/content/backTests/DropzoneContainer';

const drawerWidth = 400; // Adjust as needed

const AddCategoryDrawer = ({ open, onClose, selectedCategory }) => {
  const [name, setName] = useState('');
  const { categoryId, subCategoryId } = useParams();
  const [uniqueId, setUniqueId] = useState('');
  const [visible, setVisible] = useState(true);
  const [logoFile, setLogoFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    setName(selectedCategory?.name || '');
    setUniqueId(selectedCategory?.unique_id || '');
    setVisible(selectedCategory?.visible || false);
  }, [open, selectedCategory]);

  const validate = () => {
    const errors = {};
    if (!name) errors.name = 'Name is required';
    if (!selectedCategory && !logoFile && !selectedCategory?.logo)
      errors.logoFile = 'Logo is required';
    if (!uniqueId) errors.uniqueId = 'Portfolio ID is required';
    return errors;
  };

  const handleSave = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
      name: name,
      unique_id: uniqueId,
      visible: visible,
      date_added: moment().toISOString(),
      methodology: selectedCategory?.methodology || '',
      logo: selectedCategory?.logo || '',
      cover_image: selectedCategory?.logo || '',
      type: 'portfolio',
    };
    let formData = new FormData();
    if (selectedCategory && logoFile) {
      if (logoFile) formData.append('logo', logoFile);
      if (bannerFile) formData.append('cover_image', bannerFile);
      const payload = {
        id: selectedCategory?.id,
      };
      dispatch(updatePortfolioImage(payload, formData));
    } else {
      if (logoFile) formData.append('logo', logoFile);
      if (bannerFile) formData.append('cover_image', bannerFile);
    }
    const action = selectedCategory ? putSubCategory : postSubCategories;
    const requestPayload = selectedCategory
      ? {
          ...payload,
          subCategoryId: selectedCategory.id,
          id: selectedCategory.id,
          portfolio_id: selectedCategory.portfolio_id || selectedCategory.id,
        }
      : payload;
    dispatch(action(requestPayload, formData))
      .then((res) => {
        onClose();
        toast.success(
          `PortFolio ${selectedCategory ? 'updated' : 'added'} successfully`
        );
        setBannerFile(null);
        setLogoFile(null);
        dispatch(getCategoriesList(0, 10));
        dispatch(getSubCategoriesList(0, 10));
      })
      .catch((e) => {
        toast.error(e?.detail || 'Failed to save portfolio');
      });
  };

  return (
    <>
      {/* Backdrop */}
      {open && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.grey[900],
            opacity: 0.5,
            zIndex: 1200, // Make sure it is behind the drawer but above other elements
          }}
          onClick={onClose}
        />
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{ width: drawerWidth, flexShrink: 0 }}
        variant="persistent"
      >
        <Box
          sx={{
            width: drawerWidth,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="h6" flex={1}>
              {selectedCategory ? 'Edit Portfolio' : 'Add Portfolio'}
            </Typography>
            <IconButton onClick={onClose} edge="end">
              <CloseIcon />
            </IconButton>
          </Box>

          <Box component="form" noValidate autoComplete="off" flex={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor="name">Name</InputLabel>
                <TextField
                  id="name"
                  placeholder="Name"
                  fullWidth
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={!!errors.name}
                  helperText={errors.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box mb={1}>
                  <InputLabel htmlFor="logo">Logo</InputLabel>
                </Box>
                <DropzoneContainer
                  file={logoFile}
                  setFile={setLogoFile}
                  uploadProgress={uploadProgress}
                  setUploadProgress={setUploadProgress}
                  error={!!errors.logoFile}
                  helperText={errors.logoFile}
                  buttonName={'Upload Logo'}
                  title={'Drag and drop an Image file here'}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mb={1}>
                  <InputLabel htmlFor="banner">Banner</InputLabel>
                </Box>
                <DropzoneContainer
                  file={bannerFile}
                  setFile={setBannerFile}
                  uploadProgress={uploadProgress}
                  setUploadProgress={setUploadProgress}
                  error={!!errors.bannerFile}
                  helperText={errors.bannerFile}
                  buttonName={'Upload Banner'}
                  title={'Drag and drop an Image file here'}
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel htmlFor="uniqueId">Portfolio ID</InputLabel>
                <TextField
                  id="uniqueId"
                  placeholder="Portfolio ID"
                  fullWidth
                  variant="outlined"
                  value={uniqueId}
                  onChange={(e) => setUniqueId(e.target.value)}
                  error={!!errors.uniqueId}
                  helperText={errors.uniqueId}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SubtitlesIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <InputLabel sx={{ mr: 2 }}>Visible</InputLabel>
                  <Switch
                    checked={visible}
                    onChange={() => setVisible(!visible)}
                    color="primary"
                  />
                  <Typography variant="body1" fontWeight={600} sx={{ ml: 2 }}>
                    {visible ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
            <Button variant="contained" color="inherit" onClick={onClose}>
              Cancel
            </Button>
            <AnimateButton>
              <PrimaryButton onClick={handleSave} title="Save" />
            </AnimateButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddCategoryDrawer;
