// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  authData: {},
  captureList: [],
};

const slice = createSlice({
  name: 'captures',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    createCaptureSuccess(state, action) {
      state.captureList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { hasError, createCaptureSuccess } = slice.actions;

// ----------------------------------------------------------------------
export function createCapture(payload) {
  return async (dispatch) => {
    try {
      let response = await axios.post(`/captures`, payload);

      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function getCaptures(payload) {
  return async (dispatch) => {
    try {
      let response = await axios.get(`/captures`, payload);
      dispatch(createCaptureSuccess(response.data?.captures));
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function restoreCapture(fileId) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`restore`, {
        filename: fileId,
      });
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function deleteCapture(fileId) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`capture/${fileId}`, {
        filename: fileId,
      });
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
