import { Box, CssBaseline, styled, useTheme } from "@mui/material";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';
import Footer from './Footer';
import { useSelector } from 'react-redux';

const Main = styled('main')(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.default,
  position: 'relative',
}));

const Content = styled('div')({
  flexGrow: 1,
  overflow: 'auto',
});

const MainLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { categoriesList } = useSelector((state) => state.category);
  const { pathname } = useLocation();
  useEffect(() => {
    if (!pathname.includes(`/assets`)) {
      if (categoriesList[0]?.id) {
        navigate(`/assets/${categoriesList[0]?.id}`);
      }
    }
  }, [categoriesList[0]?.id]);
  const [sidebarDrawerOpen, setSidebarDrawerOpen] = useState(false);
  const toggleSidebarDrawer = (open) => {
    setSidebarDrawerOpen(open);
  };
  return (
    <Box display="flex" minHeight="100vh">
      <CssBaseline />
      <Sidebar
        sidebarDrawerOpen={sidebarDrawerOpen}
        toggleSidebarDrawer={toggleSidebarDrawer}
        setSidebarDrawerOpen={setSidebarDrawerOpen}
      />
      <Main theme={theme}>
        <Header toggleSidebarDrawer={toggleSidebarDrawer} />
        <Content>
          <Outlet />
        </Content>
        <Footer />
      </Main>
    </Box>
  );
};

export default MainLayout;
