import { createRoot } from "react-dom/client";

import App from "App";
import { ConfigProvider } from "contexts/ConfigContext";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "reportWebVitals";
import * as serviceWorker from "serviceWorker";
import { persister, store } from "store";
import "./assets/style.css";

// ==============================|| REACT DOM RENDER ||============================== //

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </PersistGate>
  </Provider>
);

serviceWorker.unregister();
reportWebVitals();
