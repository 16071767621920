import { Button, useTheme } from "@mui/material";

const LightButton = (props) => {
  const theme = useTheme();
  return (
    <>
      <Button
        sx={{
          background: theme.palette.custom.secondary,
          // boxShadow: "0px 10px 40px 0px rgba(85, 85, 85, 0.12)",

          ":hover": {
            background: theme.palette.custom.secondary,
            boxShadow: "none",
          },
        }}
        type="button"
        variant="contained"
        {...props}
      >
        {props.title}
      </Button>
    </>
  );
};

export default LightButton;
