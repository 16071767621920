import { useMediaQuery, useTheme } from "@mui/material";
import NavigationScroll from "layout/NavigationScroll";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import router from "routes";
import { useDispatch } from "store";
import { checkIsMobile } from "store/slices/common";
import ThemeCustomization from "themes";
import { Toaster } from 'react-hot-toast';


function App() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(checkIsMobile(isMobile));
  }, [isMobile]);

  return (
    <ThemeCustomization>
      <Toaster />
      <NavigationScroll>
        <RouterProvider router={router} />
      </NavigationScroll>
    </ThemeCustomization>
  );
}

export default App;
