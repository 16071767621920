import React, { useState } from "react";
import ReactQuill from "react-quill";
import katex from "katex";
import "katex/dist/katex.min.css";
import "react-quill/dist/quill.snow.css";

window.katex = katex;

const Editor = ({ content, setContent }) => {
  const [theme, setTheme] = useState('snow');
  const [placeholder] = useState('Write something...');

  const handleChange = (html) => {
    setContent(html);
  };

  const handleThemeChange = (newTheme) => {
    if (newTheme === 'core') newTheme = null;
    setTheme(newTheme);
  };

  return (
    <div>
      <ReactQuill
        theme={theme}
        onChange={handleChange}
        value={content}
        modules={Editor.modules}
        formats={Editor.formats}
        bounds={'.app'}
        placeholder={placeholder}
      />
    </div>
  );
};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],

    ["link", "image", "formula"],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ["clean"], // remove formatting button
  ],
  clipboard: {
    matchVisual: false, // toggle to add extra line breaks when pasting HTML
  },
};

export default Editor;
