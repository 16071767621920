import { Button, useTheme } from "@mui/material";

const PrimaryButton = (props) => {
  const theme = useTheme();
  return (
    <>
      <Button
        sx={{
          background: "linear-gradient(-180deg,#284476,#506489)",
          boxShadow: "0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)",
          color: theme.palette.common.white,

          ":hover": {
            background: "linear-gradient(-180deg,#284476,#506489)",
            boxShadow: "none",
          },
          "&.Mui-disabled": {
            background: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
          },
        }}
        type="button"
        variant="contained"
        {...props}
      >
        {props.title}
      </Button>
    </>
  );
};

export default PrimaryButton;
