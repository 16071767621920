export default function componentStyleOverrides(theme, borderRadius) {
  const menuSelectedBack = theme.palette.secondary.light;
  const menuSelected = theme.palette.secondary.dark;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          ...theme.typography.f16,
          fontWeight: 700,
          padding: theme.spacing(1.5, 2),
          borderRadius: "8px",
          color: theme.palette.text.primary,
          textTransform: "inherit",

          [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1, 2),
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {},
        colorSecondary: {
          background: theme.palette.secondary.main,
          "&:hover": {
            background: theme.palette.secondary.dark,
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        outlined: {
          border: "1px dashed",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            "&:hover": {
              backgroundColor: menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: menuSelected,
            },
          },
          "&:hover": {
            backgroundColor: menuSelectedBack,
            color: menuSelected,
            "& .MuiListItemIcon-root": {
              color: menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          minWidth: "12px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.dark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
        },
        input: {
          color: theme.palette.text.dark,
          "&::placeholder": {
            color: "rgba(35, 35, 34, 0.4)",
            fontSize: "16px",
            fontWeight: 400,
          },
          "&:-webkit-autofill": {
            WebkitTextFillColor: theme.palette.text.dark,
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          marginTop: theme.spacing(1),
          backgroundColor: "transparent",

          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[400],
          },
          "&:hover $notchedOutline": {
            borderColor: theme.palette.custom.secondary,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.custom.secondary,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.custom.secondary,
          },

          "& .MuiOutlinedInput-input:not(:placeholder-shown) + .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid #d0d0d0",
            },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
        },
        input: {
          fontWeight: 500,
          padding: "12px 16px",
          background: theme.palette.common.white,
          borderRadius: "8px",

          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        multiline: {
          borderRadius: "10px !important",
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: "8px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          marginTop: theme.spacing(1),
          backgroundColor: "#ffffff",
          border: "1px solid #2727271A",

          "& .MuiFilledInput-notchedOutline": {
            borderColor: theme.palette.grey[400],
          },
          "&:hover $notchedOutline": {
            borderColor: theme.palette.primary.light,
          },
          "&:hover .MuiFilledInput-notchedOutline": {
            borderColor: theme.palette.secondary.main,
          },
          "&.Mui-focused .MuiFilledInput-notchedOutline": {
            borderColor: theme.palette.secondary.main,
          },
          "& .MuiFilledInput-input:not(:placeholder-shown) + .MuiFilledInput-notchedOutline":
            {
              border: "1px solid #d0d0d0",
            },
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
          "&.MuiInputBase-multiline": {
            padding: "0",
          },

          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "1px solid transparent",
          },

          "&::before": {
            borderBottom: "1px solid transparent",
          },
          "&::after": {
            borderBottom: "1px solid transparent",
          },
        },

        input: {
          fontSize: "16px",
          fontWeight: 500,
          padding: "12px 16px",
          background: "transparent",
          border: "1px solid transparent",
          borderRadius: "8px",
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        multiline: {
          borderRadius: "10px !important",
          "&.MuiInputBase-inputMultiline": {
            borderRadius: "10px !important",
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: "8px",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.palette.grey[300],
          },
        },
        mark: {
          backgroundColor: theme.palette.common.white,
          width: "4px",
        },
        valueLabel: {
          color: theme.palette.primary.light,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-tag": {
            background: theme.palette.secondary.light,
            borderRadius: 4,
            color: theme.palette.text.dark,
            ".MuiChip-deleteIcon": {
              color: theme.palette.secondary[200],
            },
          },
          "& .MuiOutlinedInput-root": {
            padding: "5px",
          },
        },
        // listbox: {
        //   height: "255px !important",
        //   [theme.breakpoints.down("md")]: {
        //     height: "200px !important",
        //   },
        // },
        option: {
          minHeight: "30px !important",
        },
        paper: {
          border: "1px solid #ccc",
          borderRadius: "8px",

          padding: "8px",
          backgroundColor: "#f9f9f9",
        },
        popper: {
          borderRadius: `0`,
          marginTop: "10px !important",
          boxShadow:
            "0px 8px 10px -5px rgb(0 0 0 / 1%), 0px 16px 24px 2px rgb(0 0 0 / 1%), 0px 6px 30px 5px rgb(0 0 0 / 1%)",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          "&.Mui-checked": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          "&.Mui-checked": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          // color: theme.palette.primary.dark,
          background: theme.palette.primary[200],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: "1.5px solid #DFDFDF",
          width: "100%",

          [theme.breakpoints.down("md")]: {
            width: "max-content",
          },
        },
        indicator: {
          backgroundColor: theme.palette.custom.secondary,
        },
        scrollButtons: {
          display: "block",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...theme.typography.f14,
          fontWeight: 600,
          textTransform: "capitalize",
          color: theme.palette.custom.tabColor,

          "&.Mui-selected": {
            color: theme.palette.custom.secondary,
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "12px 0 12px 0",
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "0px !important",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "4px",
          fontSize: "14px",
          fontWeight: 500,
          color: theme.palette.grey.textColor,
          borderColor: "#e9ecef",

          "&.MuiTableCell-head": {
            fontSize: "12px",
            fontWeight: 700,
            background: "#f6f9fc",
            color: "#8898aa",
            textTransform: "uppercase",
            letterSpacing: "1px",
            borderTop: "1px solid #e9ecef",
            borderBottom: "1px solid #e9ecef",
            padding: "8px",
          },
        },
      },
    },

    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[400],
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiListItemText-primary": {
            ...theme.typography.f16,
            color: theme.palette.text.primary,
            fontWeight: 500,
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 500,
          color: theme.palette.grey[700],
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 44,
          height: 24,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 2,
            background: "transparent",
            color: "#ffffff",
            "&:hover": {
              background: "transparent",
            },
            "&.Mui-checked": {
              transform: "translateX(20px)",
              color: theme.palette.common.white,
              "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.custom.secondary,
                opacity: 1,
                border: "none",
              },
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 20,
            height: 20,
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.custom.borderColor,
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
              duration: 500,
            }),
          },
        },
      },
    },
  };
}
