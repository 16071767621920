import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { useDispatch } from 'store';
import {
  downloadForeBackTestCSV,
  getBackTest,
  uploadBackTestsCSV,
} from 'store/slices/evalmetrics';
import PrimaryButton from 'ui-component/button/PrimaryButton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DropzoneContainer from './DropzoneContainer';

const BackTests = ({ selectedSubCategory }) => {
  const theme = useTheme();
  const [file, setFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [BackTestsList, setBackTestsList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [assetsName, setAssetsName] = useState();
  const [open, setOpen] = useState(false); // State to control the dialog
  const dispatch = useDispatch();
  const { categoryId, subCategoryId } = useParams();

  const fetchData = () => {
    const payload = {
      assetId: subCategoryId || categoryId,
      limit: limit,
      skip: skip,
      type: subCategoryId ? 'assets' : 'portfolio',
    };
    dispatch(getBackTest(payload)).then((res) => {
      if (res?.status === 200) {
        setBackTestsList(res?.data);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, subCategoryId, skip, limit, categoryId]);

  const handleUploadCSV = () => {
    const assetsId = subCategoryId || categoryId;
    const formData = new FormData();
    formData.append('file', file);
    dispatch(uploadBackTestsCSV(assetsId, formData))
      .then((res) => {
        if (res?.data?.message === 'Invalid CSV Format') {
          toast.error(res?.data?.message);
        } else {
          fetchData();
          setFile(null); // Clear the file after upload
          setOpen(false); // Close the dialog after successful upload
        }
      })
      .catch((err) => {
        toast.error('Error uploading file: ');
      });
  };
  const handleClickDownload = () => {
    const assetsId = subCategoryId || categoryId;
    const type = subCategoryId ? 'assets' : 'portfolios';
    dispatch(downloadForeBackTestCSV(assetsId, type))
      .then((res) => {
        const csvData = res.data; // CSV data in string format
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `backtest_${assetsId}.csv`; // Custom file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        toast.success('Backtest downloaded successfully');
      })
      .catch((err) => {
        toast.error('Error downloading file');
      });
  };

  const dates = BackTestsList.map((item) => item.date) || [];
  const exchangeRates = BackTestsList.map((item) => item.exchange_rate) || [];
  const mqeData = BackTestsList.map((item) => item.mqe) || [];
  const sp500Data = BackTestsList.map((item) => item.sp500) || [];

  const chartOptions = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.1,
      },
    },
    markers: {
      size: 4,
      colors: ['#fff'],
      strokeColors: ['#00BAEC', '#77B6EA', '#545454'],
      strokeWidth: 2,
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: dates || [],
      title: {
        text: 'Date',
      },
    },
    yaxis: {
      title: {
        text: 'Cumulative Profits in %',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };

  const chartSeries = [
    {
      name: 'MQE',
      data: mqeData || [],
    },
    {
      name: 'S&P 500',
      data: sp500Data || [],
    },
    {
      name: 'HARI 500',
      data: exchangeRates || [],
    },
  ];

  const handleClickOpen = () => {
    setOpen(true); // Open dialog
  };

  const handleClose = () => {
    setOpen(false); // Close dialog
    setFile();
  };

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap={2}
        >
          <Typography variant="h6" fontWeight={500}>
            Backtest of MQE Algorithm on {selectedSubCategory?.name}
          </Typography>

          <Stack
            justifyContent={'flex-end'}
            direction={'row'}
            gap={2}
            sx={{
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            <AnimateButton>
              <PrimaryButton
                title={'Import Backtest'}
                onClick={handleClickOpen}
              />
            </AnimateButton>

            <AnimateButton>
              <PrimaryButton
                title={'Export Backtest'}
                onClick={handleClickDownload}
              />
            </AnimateButton>
          </Stack>
        </Stack>
      </Grid>

      {/* Dialog for Upload */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography>Upload Backtest CSV</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} mt={2}>
            <DropzoneContainer
              file={file}
              setFile={setFile}
              uploadProgress={uploadProgress}
              setUploadProgress={setUploadProgress}
              title={
                'Drag and drop a CSV file here, or click the button below to select one'
              }
              buttonName={'Upload CSV'}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            width="100%"
            p={2}
          >
            <AnimateButton>
              <PrimaryButton
                title="Save"
                style={{
                  padding: '8px 20px',
                }}
                onClick={handleUploadCSV}
                disabled={!file}
              />
            </AnimateButton>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Chart */}
      <Grid item xs={12} mt={4}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="area"
          height={350}
        />
      </Grid>
    </>
  );
};

export default BackTests;
