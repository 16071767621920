import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import BrandLogo from 'assets/images/brand/ucla-logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import AddSubcategoryModal from './AddSubcategoryModal.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePortfolios,
  getCategoriesList,
  getSubCategoriesList,
} from 'store/slices/categories.js';
import EditIcon from '@mui/icons-material/Edit';
import AddCategoryModal from './AddPortFolioModel.js';
import toast from 'react-hot-toast';
import { isUpdatedCategory, isUpdateSubCategory } from 'store/slices/common.js';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const SidebarBoxWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 10px 100px 0px #5555550A',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'auto',
  position: 'fixed',
  left: 0,
  top: 0,
  width: '280px',
  zIndex: 10,
  transition: 'transform 0.3s ease-in-out',
  [theme.breakpoints.down('md')]: {
    boxShadow: 'none',
    height: '100vh',
  },
}));

const LogoBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ListItemTextStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  ...theme.typography.f16,
  fontWeight: active ? 600 : 500,
  color: active ? theme.palette.text.primary : theme.palette.text.primary80,
}));

const SidebarItem = styled(Box)(({ theme, active }) => ({
  padding: theme.spacing(1.5),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  backgroundColor: active ? theme.palette.action.hover : 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SidebarImage = styled('img')({
  width: 40,
  height: 40,
  borderRadius: '50%',
  objectFit: 'contain',
});

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '240px',
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderBottom: 'none',

  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    // transform: 'rotate(180deg)',
  },
}));

const MainCategory = styled(Typography)(({ theme }) => ({
  ...theme.typography.f14,
  fontWeight: 600,
}));

const Sidebar = ({
  sidebarDrawerOpen,
  toggleSidebarDrawer,
  setSidebarDrawerOpen,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [categories, setCategories] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editData, setEditData] = useState('');
  const [modalPortfolioOpen, setModalPortfolioOpen] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { categoriesList, subCategoriesList } = useSelector(
    (state) => state.category
  );
  const { updateSubCategory, updateCategory } = useSelector(
    (state) => state.commonReducer
  );

  useEffect(() => {
    dispatch(getCategoriesList(skip, limit));
    dispatch(getSubCategoriesList(skip, limit));
  }, [dispatch, skip, limit, editData]);

  useEffect(() => {
    const listOfCategories = [];
    categoriesList?.length &&
      categoriesList.forEach((category) => {
        const subcategories = subCategoriesList.filter(
          (subcategory) => subcategory.portfolio_id === category.id
        );
        listOfCategories.push({
          ...category,
          subcategories: subcategories.map((subcategory) => subcategory),
        });
      });
    setCategories(listOfCategories);
  }, [
    subCategoriesList,
    categoriesList,
    updateSubCategory,
    updateCategory,
    editData,
  ]);

  useEffect(() => {
    // Set the initial expanded state to the first category
    if (categories.length > 0) {
      setExpanded(0);
    }
  }, [categories]);

  useEffect(() => {
    const currentCategoryIndex = categories.findIndex((category) =>
      category.subcategories.some(
        (subcategory) =>
          location.pathname === `/assets/${category.id}/${subcategory.id}`
      )
    );
    if (currentCategoryIndex !== -1) {
      setExpanded(currentCategoryIndex);
    } else if (categories.length > 0) {
      // Navigate to the first subcategory if no item is selected
      // const firstCategory = categories[0];
      // if (firstCategory) {
      //   navigate(`/assets/${firstCategory?.id}`);
      // }
    }
  }, [
    location.pathname,
    categories,
    navigate,
    editData,
    updateSubCategory,
    updateCategory,
  ]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isSmallScreen) {
      setSidebarDrawerOpen(false);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handlePortfolioOpenModal = () => {
    setModalPortfolioOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    dispatch(getCategoriesList(skip, limit));
    dispatch(getSubCategoriesList(skip, limit));
    setEditData('');
    setSelectedCategory('');
    dispatch(isUpdateSubCategory(!updateSubCategory));
  };
  const handlePortfolioModel = () => {
    setModalPortfolioOpen(false);
    setSelectedCategory('');
    dispatch(getCategoriesList(skip, limit));
    dispatch(getSubCategoriesList(skip, limit));
    setEditData('');
    dispatch(isUpdatedCategory(!updateCategory));
  };

  const handleEdit = (subcategory) => {
    setEditData(subcategory);
    setModalOpen(true);
  };
  const handlePortfolioEdit = (subcategory) => {
    setEditData(subcategory);
    setModalPortfolioOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteForecast = () => {
    setOpenDeleteDialog(false);
    dispatch(deletePortfolios(selectedCategory))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCategoriesList(skip, limit));
          dispatch(getSubCategoriesList(skip, limit));
          navigate(`/`);
        }
      })
      .catch((err) => {
        toast.error(err?.detail || 'Failed to delete portfolio');
      });
  };

  const menuContent = (
    <SidebarBoxWrapper>
      <Stack direction="column" gap={2.5}>
        <LogoBox>
          <Box
            component={'img'}
            src={BrandLogo}
            sx={{ width: '80%', height: '100%' }}
          />
        </LogoBox>
        <Box>
          {categories.map((category, index) => (
            <Accordion
              active={location.pathname === `/assets/${category.id}`}
              key={index}
              expanded={expanded === index}
              onChange={handleAccordionChange(index)}
            >
              <CustomAccordionSummary
                expandIcon={
                  expanded === index ? <ExpandLess /> : <ExpandMore />
                }
              >
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <SidebarImage
                    src={`${process.env.REACT_APP_BASE_URL}${category.logo}`}
                    alt={category.name}
                  />
                  <MainCategory
                    onClick={() => handleNavigation(`/assets/${category.id}`)}
                  >
                    {category.name}
                  </MainCategory>
                </Stack>
                <IconButton
                  onClick={() => {
                    handlePortfolioEdit(category.id);
                    setSelectedCategory(category);
                  }}
                >
                  <EditIcon
                    sx={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </IconButton>
              </CustomAccordionSummary>

              <AccordionDetails>
                {category.subcategories.map((subcategory, subIndex) => (
                  <SidebarItem
                    onClick={() =>
                      handleNavigation(
                        `/assets/${category.id}/${subcategory.id}`
                      )
                    }
                    active={
                      location.pathname ===
                      `/assets/${category.id}/${subcategory.id}`
                    }
                    key={subIndex}
                  >
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      gap={2}
                    >
                      <SidebarImage
                        src={`${process.env.REACT_APP_BASE_URL}${subcategory.logo}`}
                        alt={subcategory.name}
                      />
                      <ListItemTextStyled
                        active={
                          location.pathname ===
                          `/assets/${category.id}/${subcategory.id}`
                        }
                      >
                        {subcategory.name}
                      </ListItemTextStyled>
                    </Stack>
                    <IconButton
                      onClick={() => {
                        handleEdit(subcategory);
                      }}
                    >
                      <EditIcon
                        sx={{
                          height: '20px',
                          width: '20px',
                        }}
                      />
                    </IconButton>
                  </SidebarItem>
                ))}
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  my={2}
                >
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      handleOpenModal();
                      setSelectedCategory(category.id);
                    }}
                    sx={{
                      border: '1px dashed #27272766',
                    }}
                  >
                    Add Asset
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          my={2}
        >
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              handlePortfolioOpenModal();
            }}
            sx={{
              border: '1px dashed #27272766',
            }}
          >
            Add Portfolio
          </Button>
        </Box>
      </Stack>
    </SidebarBoxWrapper>
  );

  return (
    <Box height="calc(100vh)">
      {isSmallScreen ? (
        <>
          <CustomDrawer
            anchor="left"
            open={sidebarDrawerOpen}
            onClose={() => setSidebarDrawerOpen(false)}
          >
            {menuContent}
          </CustomDrawer>
          <AddSubcategoryModal
            open={modalOpen}
            onClose={handleCloseModal}
            selectedCategory={selectedCategory}
            editData={editData}
          />
          <AddCategoryModal
            open={modalPortfolioOpen}
            onClose={handlePortfolioModel}
            selectedCategory={selectedCategory}
          />
        </>
      ) : (
        <Box sx={{ width: '280px', flexShrink: 0, height: '100%' }}>
          {menuContent}
          <AddSubcategoryModal
            open={modalOpen}
            onClose={handleCloseModal}
            selectedCategory={selectedCategory}
            editData={editData}
          />
          <AddCategoryModal
            open={modalPortfolioOpen}
            onClose={handlePortfolioModel}
            selectedCategory={selectedCategory}
          />
        </Box>
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Asset</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this portfolio?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={handleCloseDeleteDialog}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteForecast}
            color="error"
            autoFocus
            sx={{ color: 'white' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sidebar;