// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  authData: {},
  forecastList: [],
};

const slice = createSlice({
  name: 'forecasts',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    foreCastSuccess(state, action) {
      state.forecastList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { hasError, foreCastSuccess } = slice.actions;

// ----------------------------------------------------------------------
export function getForecastsList(payload) {
  return async (dispatch) => {
    try {
      let response = '';
      if (payload?.type === 'assets') {
        response = await axios.get(
          `/assets/${payload?.assetsId}/forecasts?skip=${payload?.skip}&limit=${payload?.limit}`
        );
      } else {
        response = await axios.get(
          `/portfolios/${payload?.assetsId}/forecasts?skip=${payload?.skip}&limit=${payload?.limit}`
        );
      }
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function postForecast(formData) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/forecasts`, formData);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function putForecast(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/forecasts/${payload?._id}`, payload);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function deleteForecastItem(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/forecasts/${payload?.forecastId}`);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function uploadForeCastCSV(assetsId, payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/forecasts/csv/?asset_id=${assetsId}`,
        payload
      );
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function downloadForeCastCSV(assetsId, type) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${type}/${assetsId}/forecasts/csv`);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}